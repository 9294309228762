exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-return-and-dispute-policy-js": () => import("./../../../src/templates/return-and-dispute-policy.js" /* webpackChunkName: "component---src-templates-return-and-dispute-policy-js" */),
  "component---src-templates-security-deposit-conditions-js": () => import("./../../../src/templates/security-deposit-conditions.js" /* webpackChunkName: "component---src-templates-security-deposit-conditions-js" */),
  "component---src-templates-supplier-commissions-and-fees-js": () => import("./../../../src/templates/supplier-commissions-and-fees.js" /* webpackChunkName: "component---src-templates-supplier-commissions-and-fees-js" */),
  "component---src-templates-terms-and-conditions-js": () => import("./../../../src/templates/terms-and-conditions.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-js" */)
}

