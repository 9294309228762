module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WPKKQ7B","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shipper Partners","short_name":"shipper.partners","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3f995a2de53ef6f72bab703dcc66fe8a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["ar","en","fr","th"],"defaultLanguage":"ar","siteUrl":"https://shipper.partners","redirect":false,"i18nextOptions":{"lowerCaseLng":true,"saveMissing":false,"debug":false,"fallbackLng":"ar","supportedLngs":["ar","en","fr","th"],"defaultNS":"common","redirect":false,"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/terms-and-conditions","getLanguageFromPath":true},{"matchPath":"/:lang?/terms-and-conditions/:date","getLanguageFromPath":true},{"matchPath":"/:lang?/return-and-dispute-policy","getLanguageFromPath":true},{"matchPath":"/:lang?/return-and-dispute-policy/:date","getLanguageFromPath":true},{"matchPath":"/:lang?/privacy-policy","getLanguageFromPath":true},{"matchPath":"/:lang?/privacy-policy/:date","getLanguageFromPath":true},{"matchPath":"/:lang?/security-deposit-conditions","getLanguageFromPath":true},{"matchPath":"/:lang?/security-deposit-conditions/:date","getLanguageFromPath":true},{"matchPath":"/:lang?/supplier-commissions-and-fees","getLanguageFromPath":true},{"matchPath":"/:lang?/supplier-commissions-and-fees/:date","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com"],"web":[{"name":"Open Sans","file":"https://fonts.googleapis.com/css?family=Noto Kufi Arabic"}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"disableBgImageOnAlpha":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
